import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  submit() {
    this.element.requestSubmit();
    // Reload the page after submitting the form
    location.reload();
  }
}
