import Chart from 'chart.js/auto'; // TODO: Handle import more carefully? See: https://www.chartjs.org/docs/latest/getting-started/integration.html#bundle-optimization
import ChartDataLabels from 'chartjs-plugin-datalabels';
import mergeWith from 'lodash.mergewith';

import { Controller } from '@hotwired/stimulus'

const pluginsMatch = {
	'ChartDataLabels': ChartDataLabels,
};
const functionsMatch = {
	'datalabelsFormatter_labelAndPercentage': function (value, context) {
		const total = context.dataset.data.filter((i, index) => !context.chart._hiddenIndices[index])
			.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
		const percentage = (value / total) * 100;
		return `${context.chart.data.labels[context.dataIndex]}\n[${percentage.toFixed(2)}%]`;
	}
};

export default class extends Controller {
	static values = {
		plugins: String, // Comma separated plugins names, passed separately from the rest of the config, need to match in `pluginsMatch`
		config: Object,
		afterInit: Object, // Some actions to do after initialization
	}

	loadConfig() {
		const plugins = this.pluginsValue.split(',').map(pluginName => pluginsMatch[pluginName]).filter(i => !!i);

		const config = mergeWith({}, this.configValue, (objValue, newValue) => {
			if (typeof newValue === 'string') {
				const match = newValue.match(/^<function:(.+)>$/);
				if (!!match) {
					const functionName = match[1];
					return functionsMatch[functionName];
				}
			}
		});

		return {
			...config,
			plugins,
		}
	}

	connect() {
		const chart = new Chart(
			this.element,
			this.loadConfig(),
		);

		if (this.hasAfterInitValue) {
			if (this.afterInitValue.hideSlices) {
				this.afterInitValue.hideSlices.forEach(slice => {
					chart.toggleDataVisibility(slice);
				});
			}

			chart.update();
		}
	}
}
