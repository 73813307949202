import { Controller } from "@hotwired/stimulus"

/*
	Notes:
	- `[data-tabs-target="content"]` elements have a `[data-tabs-id-param]` despite having no `[data-action]`,
		it is used to match them with their corresponding `[data-tabs-target="button"]`, without having to maintain another custom attribute convention.
*/
export default class extends Controller {

	static targets = ['button', 'content'];

	static values = {
		saveToSession: String,
	};

	open(event) {
		const {
			id = null, // Warning: will be converted to a `Number` if possible. Can cause falsy values (`0`) and incorrect comparisons
		} = event.params;
		if (id === null) return;

		const stringId = `${id}`;

		this.buttonTargets.forEach(el => {
			el.classList.remove('active');

			if (el.dataset.tabsIdParam === stringId) el.classList.add('active');
		});

		this.contentTargets.forEach(el => {
			el.classList.add('d-none');
			el.classList.remove('d-block');

			if (el.dataset.tabsIdParam === stringId) {
				el.classList.add('d-block');
				el.classList.remove('d-none');
			}
		});

		if (this.hasSaveToSessionValue) window.sessionStorage.setItem(this.saveToSessionValue, stringId);
	}

	connect() {
		if (!this.hasSaveToSessionValue) return;

		const sessionName = this.saveToSessionValue;
		const sessionValue = window.sessionStorage.getItem(sessionName);
		if (!sessionValue) return;

		const buttonEls = this.buttonTargets;
		const contentEls = this.contentTargets;

		const activeButtonEls = buttonEls.filter(i => i.dataset.tabsIdParam === sessionValue);
		const activeContentEls = contentEls.filter(i => i.dataset.tabsIdParam === sessionValue);

		// If the tab to display doesn't exist, then let the default display
		if (!activeButtonEls.length || !activeContentEls.length) return;

		buttonEls.forEach(el => {
			el.classList.remove('active');
		});
		contentEls.forEach(el => {
			el.classList.add('d-none');
			el.classList.remove('d-block');
		});

		activeButtonEls.forEach(el => {
			el.classList.add('active');
		});
		activeContentEls.forEach(el => {
			el.classList.add('d-block');
			el.classList.remove('d-none');
		});

		const leftScrollButton = document.getElementById("left");
    const rightScrollButton = document.getElementById("right");
    const scrollableArea = document.getElementById("center");

    if (!leftScrollButton || !rightScrollButton || !scrollableArea) {
      return;
    }

    function updateScrollButtons() {
      if (scrollableArea.scrollLeft === 0) {
        leftScrollButton.style.display = 'none';
      } else {
        leftScrollButton.style.display = 'block';
      }

      if (scrollableArea.scrollLeft + scrollableArea.clientWidth >= scrollableArea.scrollWidth) {
        rightScrollButton.style.display = 'none';
      } else {
        rightScrollButton.style.display = 'block';
      }
    }

    function scrollLeft() {
      scrollableArea.scrollBy(-50, 0);
    }

    function scrollRight() {
      scrollableArea.scrollBy(50, 0);
    }

    leftScrollButton.addEventListener("click", () => {
      scrollLeft();
      setTimeout(updateScrollButtons, 100);
    });

    rightScrollButton.addEventListener("click", () => {
      scrollRight();
      setTimeout(updateScrollButtons, 100);
    });

    scrollableArea.addEventListener("scroll", updateScrollButtons);

    // Initial check
    updateScrollButtons();
	}
}
