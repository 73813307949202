import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

	typingTime = null;

	search(event) {
        const value = event.target.value.toLowerCase();

        clearTimeout(this.typingTimer);

        this.typingTimer = setTimeout(function() {
            const parsedUrl = new URL(window.location.href);
            parsedUrl.searchParams.set('page','1');
            parsedUrl.searchParams.set
            ('search', value);

            Turbo.visit(parsedUrl.href);
        }, 1500);
	}
}
