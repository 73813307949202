import { Controller } from '@hotwired/stimulus'

// Note: `[data-card-select-value-param]` is also used to style the clicked card

export default class extends Controller {
  // define our targets in our controller
  static targets = ["card", "input"];

  async select(event) {
	const value = event.params.value;

	this.inputTarget.value = value;

	this.cardTargets.forEach(element => {
		if (element.dataset.cardSelectValueParam === `${value}`) {
			element.classList.add('border');
			element.classList.add('border-primary');
			element.classList.add('rounded');

			return;
		}

		element.classList.remove('border');
		element.classList.remove('border-primary');
		element.classList.remove('rounded');
	});

  }
}
