import { Controller } from "@hotwired/stimulus"

/*
  Notes:
  - `[data-toggle-target="content"]` elements have a `[data-toggle-values-param]` despite having no `[data-action]`,
    it is used to match them with the corresponding `event`'s value, without having to maintain another custom attribute convention.
  - `[data-toggle-target="content"]` elements have a `[data-toggle-id-param]` despite having no `[data-action]`,
    it is used to match them with the corresponding `[data-toggle-target="select"]`'s `[data-toggle-id-param]`, without having to maintain another custom attribute convention.
  - `[data-toggle-target="select"]` elements can have a `[data-toggle-should-reset-on-hide-param]` despite it not being used with their `[data-action]`,
    it is used to find them when hiding a `content` target, without having to maintain another custom attribute convention.
*/
export default class extends Controller {
  static targets = ["content", "select"];

  connect() {
    if (this.hasSelectTarget) {
      this.selectTargets.forEach(selectEl => {
        this.toggle(selectEl.value, selectEl.dataset.toggleIdParam);
      });
    }
  }

  select(event) {
    this.toggle(event.target.value, event.params.id);
  }

  toggle(value, id = '') {
    this.contentTargets.forEach(contentEl => {
      if (id !== (contentEl.dataset.toggleIdParam || '')) return; // Only modify content elements who have the same id param as the select (or if they both have no id param)
      if (contentEl.dataset.toggleValuesParam.split(',').includes(value)) {
        contentEl.classList.remove('d-none');
        // Add required to all elements inside the content element
        contentEl.querySelectorAll('[data-required]').forEach(el => {
          el.required = true;
          el.removeAttribute('data-required');
        });
      } else {
        contentEl.classList.add('d-none');
        // Remove required from all elements inside the content element
        contentEl.querySelectorAll('[required]').forEach(el => {
          el.required = false;
          el.dataset.required = true;
        });
        this.selectTargets.forEach(selectEl => {
          if (selectEl.dataset.toggleShouldResetOnHideParam && contentEl.contains(selectEl)) {
            selectEl.value = selectEl.defaultValue;
            selectEl.dispatchEvent(new Event('input', { bubbles: true }));
          }
        });
      }
    });
  }
}
