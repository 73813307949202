import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  format (e) {
    if (e.keyCode === 8) return

    e.target.value = e.target.value.replace(/[^0-9|.|-]/g, '') // remove all charachter that is not a number, dot or dash
    e.target.value = e.target.value.substring(0, 15) // remove all charachter after the 15th one
    if (e.target.value.length == 2) {
      e.target.value = e.target.value + '.'
    }
    if (e.target.value.length == 5) {
      e.target.value = e.target.value + '.'
    }
    if (e.target.value.length == 8) {
      e.target.value = e.target.value + '-'
    }
    if (e.target.value.length == 12) {
      e.target.value = e.target.value + '.'
    }
  }
}
