import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["tooltip"];

  showOverflow(event) {
    const parentCell = this.element.closest('.stat-card__cell');
    const parentLabel = this.element.closest('.stat-card__label');
    if (parentCell) {
      parentCell.style.overflow = 'visible';
    }
    if (parentLabel) {
      parentLabel.style.opacity = 'unset';
    }
  }

  hideOverflow(event) {
    const parentCell = this.element.closest('.stat-card__cell');
    const parentLabel = this.element.closest('.stat-card__label');
    if (parentCell) {
      parentCell.style.overflow = 'hidden';
    }
    if (parentLabel) {
      parentLabel.style.opacity = '0.5';
    }
  }
}
