import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
    // define our targets in our controller
    static targets = ["input", "showIcon", "hideIcon"];

    toggle() {
        if (this.inputTarget.type === "password") {
            this.hideIconTarget.classList.remove("d-none");
            this.showIconTarget.classList.add("d-none");
            this.inputTarget.type = "text";
        } else {
            this.hideIconTarget.classList.add("d-none");
            this.showIconTarget.classList.remove("d-none");
            this.inputTarget.type = "password";
        }
    }
}
