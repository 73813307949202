import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  // define our targets in our controller
  static targets = ["content", "button"];

  setCookie(name, value, days) {
    let expires = '';
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = `; expires=${date.toUTCString()}`;
    }
    document.cookie = `${name}=${(value || '')}${expires}; path=/`;
  }

  getCookie(name) {
    const nameEQ = `${name}=`;
    const cookieArray = document.cookie.split(';');
    for (let i = 0; i < cookieArray.length; i++) {
      let cookie = cookieArray[i];
      while (cookie.charAt(0) === ' ') cookie = cookie.substring(1, cookie.length);
      if (cookie.indexOf(nameEQ) === 0) return cookie.substring(nameEQ.length, cookie.length);
    }
    return null;
  }

  show(scroll = true) {
    const contentEl = this.contentTarget;

    contentEl.classList.add('js-collapse--active');

    // Handle button accessibility
    this.buttonTarget.setAttribute('aria-expanded', 'true');

    // Save
    const id = contentEl.getAttribute('id');
    this.setCookie(id, 1, 100);

    // Scroll
    if (scroll) {
      const top = this.element.getBoundingClientRect().top + (document.scrollingElement?.scrollTop || 0);
      window.scroll({ left: 0, top: top, behavior: 'smooth' });
    }
  }

  hide() {
    const contentEl = this.contentTarget;

    contentEl.classList.remove('js-collapse--active');

    // Handle button accessibility
    this.buttonTarget.setAttribute('aria-expanded', 'false');

    // Save
    const id = contentEl.getAttribute('id');
    this.setCookie(id, 0, 100);
  }

  toggle() {
    if (this.contentTarget.classList.contains('js-collapse--transitioning')) return;

    // Add transition class here so it isn't applied on connect
    this.contentTarget.classList.add('js-collapse--transitioning');

    if (this.buttonTarget.getAttribute('aria-expanded') === 'true') {
      this.hide();
    } else {
      this.show();
    }
  }

  handleTransitionend() {
    this.contentTarget.classList.remove('js-collapse--transitioning');
  }

  connect() {
    this.contentTarget.classList.add('js-collapse');

    const cookieVal = this.getCookie(this.contentTarget.getAttribute('id'));
    if (cookieVal !== null) {
      if (cookieVal) {
        this.show(false);
      } else {
        this.hide();
      }
    } else {
      if (this.buttonTarget.getAttribute('aria-expanded') === 'true') {
        this.show(false);
      } else {
        this.hide();
      }
    }

    this.contentTarget.addEventListener('transitionend', this.handleTransitionend.bind(this));
  }

  disconnect() {
    this.contentTarget.removeEventListener('transitionend', this.handleTransitionend.bind(this));
  }
}

/* If grid-template-rows causes any trouble
export default class extends Controller {
  // define our targets in our controller
  static targets = ["content", "button"];

  setCookie(name, value, days) {
    let expires = '';
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = `; expires=${date.toUTCString()}`;
    }
    document.cookie = `${name}=${(value || '')}${expires}; path=/`;
  }

  getCookie(name) {
    const nameEQ = `${name}=`;
    const cookieArray = document.cookie.split(';');
    for (let i = 0; i < cookieArray.length; i++) {
      let cookie = cookieArray[i];
      while (cookie.charAt(0) === ' ') cookie = cookie.substring(1, cookie.length);
      if (cookie.indexOf(nameEQ) === 0) return cookie.substring(nameEQ.length, cookie.length);
    }
    return null;
  }

  show(scroll = true) {
    const contentEl = this.contentTarget;

    contentEl.classList.add('js-collapse--active');

    // Add transition base style & direct child's height to content target
    contentEl.classList.add('js-collapse--transitioning');
    contentEl.style.height = `${contentEl.firstElementChild.offsetHeight}px`;

    // Handle button accessibility
    this.buttonTarget.setAttribute('aria-expanded', 'true');

    // Save
    const id = contentEl.getAttribute('id');
    this.setCookie(id, 1, 100);

    // Scroll
    if (scroll) {
      const top = this.element.getBoundingClientRect().top + (document.scrollingElement?.scrollTop || 0);
      window.scroll({ left: 0, top: top, behavior: 'smooth' });
    }
  }

  hide() {
    const contentEl = this.contentTarget;

    contentEl.classList.remove('js-collapse--active');

    // Add transition base style & direct child's height to content target
    contentEl.classList.add('js-collapse--transitioning');
    contentEl.style.height = `${contentEl.firstElementChild.offsetHeight}px`;

    // Then remove custom height 
    window.setTimeout(() => {
      contentEl.style.height = '';
    }, 0);

    // Handle button accessibility
    this.buttonTarget.setAttribute('aria-expanded', 'false');

    // Save
    const id = contentEl.getAttribute('id');
    this.setCookie(id, 0, 100);
  }

  toggle() {
    if (this.contentTarget.classList.contains('js-collapse--transitioning')) return;

    if (this.buttonTarget.getAttribute('aria-expanded') === 'true') {
      this.hide();
    } else {
      this.show();
    }
  }

  handleTransitionend() {
    this.contentTarget.classList.remove('js-collapse--transitioning');
  }

  connect() {
    this.contentTarget.classList.add('js-collapse');

    const cookieVal = this.getCookie(this.contentTarget.getAttribute('id'));
    if (cookieVal !== null) {
      if (cookieVal) {
        this.show(false);
      } else {
        this.hide();
      }
    } else {
      if (this.buttonTarget.getAttribute('aria-expanded') === 'true') {
        this.show(false);
      } else {
        this.hide();
      }
    }

    this.contentTarget.addEventListener('transitionend', this.handleTransitionend.bind(this));
  }

  disconnect() {
    this.contentTarget.removeEventListener('transitionend', this.handleTransitionend.bind(this));
  }
}
*/
