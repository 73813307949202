import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["facturation", "passifValeur", "preProrata"]

  connect() {
    console.log('Prorata calculator connected');
    this.dateOfDeath = new Date(this.element.dataset.dateOfDeath);
    this.isLeapYear = this.checkLeapYear(this.dateOfDeath.getFullYear());
  }

  calculateProrata() {
    const totalValue = parseFloat(this.preProrataTarget.value) || 0;
    const proratedValue = this.calculateProratedValue(totalValue, this.facturationTarget.value);
    if (!isNaN(proratedValue)) {
      this.passifValeurTarget.value = proratedValue.toFixed(2);  // Update prorata value field
    }
  }

  calculateProratedValue(totalValue, facturationType) {
    const daysUpToDeath = this.calculateDaysUpToDeath(facturationType);
    const daysInYear = this.isLeapYear ? 366 : 365;  // Adjust for leap year
    const daysInMonth = this.getDaysInMonth(this.dateOfDeath);
    const daysInQuarter = this.getDaysInQuarter();  // Calculate total days in the current quarter

    switch (facturationType) {
      case 'annual':
        return (totalValue * (daysUpToDeath / daysInYear));  // Prorate by year
      case 'quarterly':
        return (totalValue * (daysUpToDeath / daysInQuarter));  // Prorate by quarter
      case 'monthly':
        return (totalValue * (daysUpToDeath / daysInMonth));  // Prorate by month
      default:
        return totalValue;
    }
  }

  calculateDaysUpToDeath(facturationType) {
    let startDate, endDate;

    switch (facturationType) {
      case 'annual':
        startDate = new Date(this.dateOfDeath.getFullYear(), 0, 1);  // January 1st of the death year
      break;
      case 'quarterly':
        const quarter = Math.floor(this.dateOfDeath.getMonth() / 3); // Get the quarter (0 to 3)
        startDate = new Date(this.dateOfDeath.getFullYear(), quarter * 3, 1); // First day of the quarter
        endDate = this.getQuarterEndDate(quarter);  // Last day of the quarter
      break;
      case 'monthly':
        startDate = new Date(this.dateOfDeath.getFullYear(), this.dateOfDeath.getMonth(), 1); // First day of the month
      break;
      default:
        startDate = new Date(this.dateOfDeath.getFullYear(), 0, 1); // Default to start of the year
      }

    const daysUpToDeath = Math.ceil((this.dateOfDeath - startDate) / (1000 * 60 * 60 * 24));  // Difference in days
    return Math.max(daysUpToDeath, 1);  // Ensure at least 1 day
  }

  getDaysInQuarter() {
    const quarter = Math.floor(this.dateOfDeath.getMonth() / 3);  // Determine the quarter
    const startDate = new Date(this.dateOfDeath.getFullYear(), quarter * 3, 1);  // First day of the quarter
    const endDate = this.getQuarterEndDate(quarter);  // Last day of the quarter

    // Calculate the total number of days in the quarter
    return Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24)) + 1;  // Difference in days (add 1 to include both start and end days)
  }

  getQuarterEndDate(quarter) {
    const year = this.dateOfDeath.getFullYear();

    // Determine the end of the quarter
    switch (quarter) {
      case 0: // Q1: January to March
        return new Date(year, 2, this.isLeapYear ? 31 : 31); // Last day of March (Feb is 29 in leap years)
      case 1: // Q2: April to June
        return new Date(year, 5, 30); // Last day of June
      case 2: // Q3: July to September
        return new Date(year, 8, 30); // Last day of September
      case 3: // Q4: October to December
        return new Date(year, 11, 31); // Last day of December
    }
  }

  getDaysInMonth(date) {
    return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
  }

  checkLeapYear(year) {
    return (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
  }
}
