import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
    // define our targets in our controller
    static targets = ["birthdate"];

    fill(event) {
        if (this.birthdateTarget.value.trim()) return;

        const value = event.target.value.replace(/\D/g, '');
        if (value.length < 6) return;

        const year = value.substring(0, 2);
        const month = value.substring(2, 4);
        const day = value.substring(4, 6);

        const currentFullYear = `${new Date().getFullYear()}`;
        const currentYear = currentFullYear.substring(currentFullYear.length - 2);
        const currentCentury = currentFullYear.substring(0, currentFullYear.length - 2);

        const century = Number(year) > Number(currentYear) ? `${Number(currentCentury) - 1}` : currentCentury;

        this.birthdateTarget.value = `${century}${year}-${month}-${day}`;
    }
}
