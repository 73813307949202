import TextareaAutogrow from 'stimulus-textarea-autogrow'

export default class extends TextareaAutogrow {
  connect() {
    super.connect()
    this.element.style.overflow = 'scroll'
  }

  autogrow() {
    // We cannot just us the default autogrow method because the textarea
    // does not have a scrollHeight when it is hidden.
    this.element.style.height = 'auto'
    let numberOfLines = this.element.value.split('\n').length
    this.element.value.split('\n').forEach(line => {
      if (line.length > 90) {
        numberOfLines += Math.ceil(line.length / 90)
      }
    })
    const scrollHeight = this.element.scrollHeight || (numberOfLines * 16) // 16 pixel is the height of a line
    const height = Math.min(Math.max(scrollHeight, 70), 500)
    this.element.style.height = height + 'px'
  }
}
