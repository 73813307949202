import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
    // define our targets in our controller
    static targets = ["gender", "familyLink"];

    fill(event) {
        if (this.genderTarget.value.trim()) return;
        if (this.familyLinkTarget.value.trim()) return;

        const value = event.target.value.replace(/\D/g, '');
        if (value.length < 9) return;

        const seqNumber = value.substring(6, 9);

        // The sequence number is even for females and odd for males
        const isFemale = seqNumber % 2 === 0;

        this.genderTarget.value = isFemale ? 'female' : 'male';
        this.familyLinkTarget.value = isFemale ? 'daughter' : 'son';
    }
}
