import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
    static values = {
        param: String,
    }

    typingTime = null;

    search(event) {
        const value = event.target.value;

        clearTimeout(this.typingTimer);

        if (!/\d{4}-\d{2}-\d{2}/.test(value)) return;

        this.typingTimer = setTimeout(() => {
            const parsedUrl = new URL(window.location.href);
            parsedUrl.searchParams.set(this.paramValue, value);

            Turbo.visit(parsedUrl.href);
        }, 1500);
    }

    empty() {
        const parsedUrl = new URL(window.location.href);
        parsedUrl.searchParams.delete(this.paramValue);

        Turbo.visit(parsedUrl.href);
    }
}
