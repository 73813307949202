import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    console.log('clearing form...');
    this.element.addEventListener("submit", (event) => this.clear(event));
  }

  clear(event) {
    // Find the closest form element to the event target
    const form = event.target.closest('form');

    if (form) {
      // Reset the form
      form.requestSubmit();
      setTimeout(() => {
        form.reset();
        console.log('Form reset successfully');
      }, 1000);
    } else {
      console.log('No form found to reset');
    }
  }
}
