import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["disabler", "field"];

  connect() {
    if (!this.disablerTargets.length || !this.fieldTargets.length) return;

    this.update();
  }

  update() {
    this.handleDisableState(this.disablerTargets.some(disablerEl => disablerEl.type === 'file' ? disablerEl.files.length === 0 : !disablerEl.value));
  }

  handleDisableState(disabled = false) {
    this.fieldTargets.forEach(fieldEl => {
      fieldEl.disabled = disabled;
    });
  }
}
