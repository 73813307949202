import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  // Define our targets in our controller
  static targets = ["copyIcon", "checkIcon"];

  static values = {
    text: String,
    redirectionLink: String,
    info: String,
  };

  connect() {
    // Only generate clipboard text if textValue is not already provided
    if (!this.hasTextValue || !this.textValue) {
      const [taskId, locale] = this.infoValue.split(',');
      const url = this.generateUrl(locale, taskId);
      const clipboardText = this.generateClipboardText(locale, url);

      this.textValue = clipboardText;
    }
  }

  generateUrl(locale, taskId) {
    const encodedId = btoa(taskId); // Base64 encoding in JavaScript
    return `https://app.legacio.eu/nl/suppliers_partners/mark_as_done/${encodedId}?locale=${locale}`;
  }

  generateClipboardText(locale, url) {
    if (locale === "fr") {
      return `Merci de cliquer sur le lien ci-dessous pour indiquer à la famille et à Legacio que cette démarche a été traitée : ${url}`;
    } else {
      return `Klik op de link hieronder om de familie en Legacio te laten weten dat dit verzoek is verwerkt: ${url}`;
    }
  }

  // Define our copy action
  async copy() {
    await this.copyToClipboard(this.textValue);

    if (this.hasRedirectionLinkValue) {
      window.open(this.redirectionLinkValue, '_blank');
    }
    if (this.hasCheckIconTarget && this.hasCopyIconTarget) {
      this.checkIconTarget.classList.remove('d-none');
      this.copyIconTarget.classList.add('d-none');
      setTimeout(() => {
        this.checkIconTarget.classList.add('d-none');
        this.copyIconTarget.classList.remove('d-none');
      }, 2000);
    }
  }

  async copyToClipboard(text) {
    await navigator.clipboard.writeText(text);
  }
}
