// app/javascript/controllers/hovercard_controller.js

import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["card"];
  static values = { url: String };

  show() {
    if (this.hasCardTarget) {
      this.cardTarget.classList.remove("d-none");
    } else {
      fetch(this.urlValue)
        .then((r) => r.text())
        .then((html) => {
          const fragment = document
            .createRange()
            .createContextualFragment(html);
          this.element.appendChild(fragment);
        });
    }
  }

  hide() {
    if (this.hasCardTarget) {
      this.cardTarget.classList.add("d-none");
    } else {
      setTimeout(() => {
        if (this.hasCardTarget) {
          this.cardTarget.classList.add("d-none");
        } else {
          setTimeout(() => {
            if (this.hasCardTarget) {
              this.cardTarget.classList.add("d-none");
            }
          }, 500);
        }
      }, 100);
    }
  }

  disconnect() {
    if (this.hasCardTarget) {
      this.cardTarget.remove();
    }
  }
}
